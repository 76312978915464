<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons"></div>
    </header>

    <div class="main-content">
      <div class="dashboard">
        <div class="pane active-users-total">
          <span class="label">Actieve gebruikers</span>
          <span class="value">{{ activeUsersTotal }}</span>
        </div>

        <div class="pane latest-user-trackings" v-if="latestUserTrackings && latestUserTrackings.length > 0">
          <div class="label">Laatste wijzigingen</div>
          <div>
            <div class="table-element">
              <table class="listData">
                <thead>
                <tr>
                  <th class="email">
                    <div>E-mail</div>
                  </th>
                  <th>
                    <div>Pad</div>
                  </th>
                  <th>
                    <div>Aangemaakt</div>
                  </th>
                </tr>
                </thead>

                <tbody v-if="latestUserTrackings.length > 0">
                <tr @click="$router.push({name: 'admin.usertracking', params: { id: listObj.id }})" :key="listObj.id" v-for="listObj in latestUserTrackings">
                  <td>{{ listObj.user.email }}</td>
                  <td>{{ listObj.path }}</td>
                  <td>{{ listObj.when.toFormat('dd/LL/yyyy HH:mm:ss') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/admin/PageTitle'
import { ApiService } from '@/services/admin/api.service'
import { DateTime } from 'luxon'

export default {
  data () {
    return {
      activeUsersTotal: 0,
      latestUserTrackings: null
    }
  },
  components: { PageTitle },
  async mounted () {
    this.activeUsersTotal = (await ApiService.getActiveUsersTotal()).data.total

    if (this.checkPermissions(['user_tracking.view'])) {
      await ApiService.fetchUserTrackings({
        page: 0,
        order_by: 'user_tracking.when',
        order_dir: 'desc'
      }).then(res => {
        if (res.status === 200) {
          this.latestUserTrackings = res.data.users
          this.latestUserTrackings.map(obj => {
            obj.when = DateTime.fromISO(obj.when)
            return obj
          })
          this.latestUserTrackings = this.latestUserTrackings.slice(0, 10)
        }
      })
    }
  },
  methods: {}
}
</script>
